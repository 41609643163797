.auth-splash-container {
  position: fixed;
  height: 100%;
}

.auth-splash-hidden {
  visibility: hidden;
  height: 100vh;
}

.splash-bkg {
  position: relative;
  height: 100%;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-img {
  margin-right: 25px;
}

.auth-image {
  height: 100%;
}

.auth-logo {
  width: 100%;
  position: absolute;
  top: 65px;
}

.copyright-disclaimer {
  width: 100%;
  position: absolute;
  bottom: 60px;
  color: white;
  font-size: 14px;
  text-align: center;
}
