.dropdown-menu:not(.show) {
  display: unset;
  margin-top: 30px;
  visibility: hidden;
  opacity: 0;
}

.user-dropdown .show {
  display: block;
  margin-top: 17px;
  visibility: visible;
  opacity: 1;
}

.dropdown-item {
  color: #ffffff;
}

.dropdown-item svg {
  width: 30px;
  margin-right: 15px;
}

.dropdown-item:hover,
.dropdown-item:focus,
.dropdown-item:active {
  border: none;
  outline: none;
}
