.partners-logos {
  /* position: fixed; */
  position: absolute;
  bottom: 0;
  /* right: 0; */
  /* width: 73.4%; */
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
  background-color: white;
  padding: 5px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 30px;
  text-align: center;
}

.partners-logos img {
  height: 50px;
}

.img-span {
  width: 200px;
  padding-left: 10px;
}

.poweredByText {
  color: #000000;
  font-weight: 700;
  text-transform: uppercase;
}

@media (max-width: 1440px) {
  .partners-logos img {
    height: 40px;
  }
  .img-span {
    width: 120px;
    padding-left: 3px;
  }
  .poweredByText {
    font-size: 10px;
  }
}

@media (max-width: 1280px) {
  .partners-logos img {
    height: 40px;
  }
  .img-span {
    width: 100px;
    padding-left: 3px;
  }
  .poweredByText {
    font-size: 10px;
  }
  /* .partners-logos {
    width: 73.1%;
  } */
}
