.sublogo-container-registration {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 53px;
  background-color: white;
  margin-top: 40px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.default-option-hidden {
  display: none !important;
}

.class-font-colour-gray {
  color: #7a7a7a !important;
}

.select-input {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
